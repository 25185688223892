import React from 'react';
import CoverScreen from './CoverScreen';
import { Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next';

const addPhotoUrl = "add-a-photo.png"

const TextHintOverlay = ({ positionsPending = null }) => {
    const boxStyle = {
        borderRadius: '50px',
        backgroundColor: 'rgba(255, 255, 255)', // white
        // backgroundColor: 'rgba(255, 255, 255, 0.5)', // 50% transparent white
        padding: '1em',
        marginBottom: "3em",
        display: 'inline-block'
    }
    const { t } = useTranslation();


    return (
        <CoverScreen zIndex={4} transparent={true} justify={"space-between"}>
            <Box sx={boxStyle}>
                <Typography >
                    {t("look at arrows")}
                    <img style={{height: "1.1em"}} src={addPhotoUrl} alt="add photo" />
                </Typography>
            </Box>
            {positionsPending ?
                <Box sx={boxStyle}>
                    <Typography >
                        {`${positionsPending} `}
                        {t("positions pending")}
                    </Typography>
                </Box>
                : <Box></Box>}
        </CoverScreen>
    );
};

export default TextHintOverlay;