// de.js
export default {
    "Language Select": "Switch to English",
    "How does it work": "Wie funktioniert's?",
    "panoramaExplanation": 'Scanne den Himmel von deinem Balkon aus. Die Sensoren in deinem Handy liefern genaue Daten über Standort und Ausrichtung.',
    "calculateExplanation": "Die KI berechnet automatisch die beste Ausrichtung und Systemgröße. Über deinen Standort werden genaue Wetterdaten abgerufen.",
    "resultsExplanation": 'Schau dir sofort an, wie viel CO₂ und € du mit einer eigenen kleinen Anlage sparen kannst.',
    "First we need some permissions:": "Zunächst brauchen wir einige Berechtigungen:",
    "Orientation access": "Wir benötigen die Ausrichtung deines Gerätes zur Erstellung des Panoramas.",
    "Location access": "Wir benötigen Deinen Standort, um die lokale Sonneneinstrahlung, das Wetter usw. zu berechnen.",
    "Camera access": "Wir benötigen Deine Kamera, um Bilder vom Himmel aufzunehmen.",
    "Next": "Weiter",
    "Use Test Data": "Benutze Testdaten",
    "readyForProcessing": "Bereit zur Verarbeitung",
    "creatingPanorama": "Erstelle Panorama...",
    "loadingAI": "Lade KI...",
    "segmenting": "Analysiere Verschattung...",
    "loadingWeather": "Lade lokale Wetterdaten...",
    "calculatingBestSystem": "Finde bestes System...",
    "resultsReady": "Fertig!",
    "showResults": "Ergebnisse anzeigen",
    "Congrats": "Glückwunsch!",
    "CongratsText": "Ein Balkonkraftwerk lohnt sich für dich:",
    "Other Systems": "Weitere Systeme",
    "Order Now": "Jetzt bestellen",
    "Order": "bestellen",
    "years": " Jahren",
    "co2savings tooltip": "So viel CO₂ wird deine Anlage pro Jahr sparen! Zum Vergleich: ein Baum bindet etwa 25kg CO₂ pro Jahr.",
    "moneysavings tooltip": "So viel Geld sparst du pro Jahr!",
    "paysoffafter tooltip": `Nach so viel Zeit hat sich das Kraftwerk selbst bezahlt. Typische Lebensdauer: mehr als 25 Jahre!`,
    "returns tooltip": `So viel Rendite bekommst du auf dein Geld. Zum Vergleich: auf einem typischen Sparkonto sind es nur 4%.`,
    "Powered by": "von ",
    "init panorama": "Starte Panorama-Creator...",
    "calibrate compass": "Bewege und schwenke dein Handy in einer 8er-Bewegung um den Kompass zu kalibrieren.",
    "calibrate compass done": "Kalibrierung abgeschlossen!",
    "Go to balcony": "Gehe auf deinen Balkon...",
    "I am there": "Ich bin da!",
    "Get ready 1": "Im nächsten Schritt scannen wir den Himmel.",
    "Get ready 2": "Stelle dich an den vorderen Teil deines Balkons und schaue geradeaus.",
    "look at arrows": "Drehe dich in Richtung des Pfeils und suche die Kameraposition  ",
    "positions pending": "Position(en) zu überprüfen",
    "permissions blocked warning": "Eine der Berechtigungen wurde verweigert. Die App kann ohne sie nicht funktionieren. Wenn Sie nicht aufgefordert wurden, Berechtigungen zu erteilen, blockiert möglicherweise Ihr Browser den Zugriff. Sie können versuchen, die Seite neu zu laden oder die Browsereinstellungen zu überprüfen, um Berechtigungen zu gewähren.",
    "reload": "Neu laden",
    "request next": "Berechtigungen anfordern",
    "Saves": "Spart",
    "Return on Investment": "Rendite",
    "kg / a": "kg / Jahr",
    "€ / a": "€ / Jahr",
    "Pays off in": "Amortisiert nach",
    "LongRun": "Langfristig lohnenswert!",
    "LongRunText": "Ein Balkonkraftwerk ist langfristig lohnenswert für dich. Für die Umwelt allemal!",
    "NotWorth": "Eher nicht lohnenswert.",
    "NotWorthText": "Ein Balkonkraftwerk lohnt sich für dich erst nach sehr langer Zeit.",
    "winter": "Winter",
    "summer": "Sommer",
    "spring/autumn": "Frühling/Herbst",
    "hour": "Uhrzeit",
    "production": "Leistung in %",
    "visit site": "Zur Website",
    "optimalSolarSystem": "Das optimale Solarsystem für deinen Balkon produziert {{yearlyYield}} kWh Strom pro Jahr. Bei einem Preis von {{electricityPrice}} € pro kWh ist dieser Strom {{electricityWorth}} € wert.",
    "calc explanation": "Die KI analysiert die Himmelsansicht von deinem Balkon. Zusammen mit lokalen Wetterdaten ist es möglich, das genaue Leistungsprofil zu berechnen und es mit einem typischen deutschen Haushaltsverbrauchsprofil von 2000 kWh pro Jahr zu vergleichen.",
    "display explanation": "Zeige Erklärung",
    "Recommended systems": "Empfohlene Systeme",
    // other translations...
};
