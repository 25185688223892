import React from 'react';
import Box from '@mui/material/Box';
import config from '../../config/current';

const LogoBox = () => {
    return (
        <Box m={2} sx={{ maxWidth: "80%", flexShrink: 1, overflow: "auto", paddingTop: 3 }}>
            <a href="https://www.balkonsonne.app/" target="_blank" rel="noreferrer">
                <img src={config.logoPath} alt="Logo" style={{ maxWidth: "90%", maxHeight: "90%" }} />
            </a>
        </Box>
    );
};

export default LogoBox;
