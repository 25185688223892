import { MenuItem, Select, SvgIcon, Theme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   menuItem: {
//     color: "red", // Change the color to red
//     textAlign: "center", // Center the content
//   },
//   localeMenu: {
//     alignItems: "center",
//     display: "flex",
//     "& > svg": {
//       marginRight: 2,
//     },
//     border: "none",
//   },
// }));

const localeMenu = {
  alignItems: "center",
  display: "flex",
  "& > svg": {
    marginRight: 2,
  },
  border: "none",
};

const menuStyles = {
  color: "black",
  borderColor: "white",
  borderWidth: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .MuiSelect-icon": {
    // hide the arrow
    display: "none",
  },
};

export default function LanguageSelector({appendUserAction}) {
  const { t, i18n } = useTranslation();
  const locales = ["en", "de", "fr"];
  const locale = i18n.language;
  // const classes = useStyles();

  const getLangName = (locale) => {
    const languageNames = new Intl.DisplayNames([locale], {
      type: "language",
    });
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return capitalizeFirstLetter(languageNames.of(locale));
  };

  console.log("locale", locale);

  const switchLanguage = (newVal) => {
    appendUserAction("Language changed to " + getLangName(newVal));
    let newLanguage = newVal;
    i18n.changeLanguage(newLanguage);
  };

  return locales && locales.length > 1 ? (
    <div style={localeMenu}>
      <Select
        sx={menuStyles}
        // className={classes.localeMenu}
        value={locale}
        onChange={(e) => {
          switchLanguage(e.target.value);
        }}
      >
        {locales?.map((availableLocale) => (
          <MenuItem key={availableLocale} value={availableLocale}>
            <img
              style={{ height: "1em", marginRight: "0.5em" }}
              src="/language_switch.png"
              alt="Language Switch"
            />
            {getLangName(availableLocale)}
          </MenuItem>
        ))}
      </Select>
    </div>
  ) : null;
}
