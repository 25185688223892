
// quick fix 
const calibrationTimeout = process.env.REACT_APP_CONFIG_TO_USE == 'dev' ? 1000 : 5000

export function startCompassListener(
    setCompass,
    setOrientationCompassOffset,
    setHeading,
    controlsOffsetInitialized,
    setControlsOffsetInitialized
) {
    let absoluteCompassHeading = null
    let relativeCompassHeading = null

    let prevOffsets = Array.from({ length: 1000 }, () => NaN);

    const calcStability = (offset) => {
        let stability = 0;
        if (isNaN(offset)) {
            return stability;
        }
        else {
            prevOffsets.shift();
            prevOffsets.push(offset);
            let sum = prevOffsets.reduce((a, b) => a + b, 0);
            let avg = sum / prevOffsets.length;
            let diffs = prevOffsets.map((x) => Math.abs(x - avg));
            let sumDiffs = diffs.reduce((a, b) => a + b, 0);
            stability = sumDiffs / prevOffsets.length;
        }
        return stability;
    }

    if (!window.DeviceOrientationEvent) {
        console.warn("DeviceOrientation API not available");
        return;
    }

    let absoluteListener = function (e) {
        if (!e.absolute || e.alpha == null || e.beta == null || e.gamma == null)
            return;
        let compass = -(e.alpha + e.beta * e.gamma / 90);
        compass -= Math.floor(compass / 360) * 360; // Wrap into range [0,360].
        setCompass(compass);
        absoluteCompassHeading = compass;
        window.removeEventListener("deviceorientation", webkitListener);
    };

    let webkitListener = function (e) {
        let compass = e.webkitCompassHeading;
        if (compass != null && !isNaN(compass)) {
            setCompass(compass);
            absoluteCompassHeading = compass;
            window.removeEventListener("deviceorientationabsolute", absoluteListener);
        }
    }

    const calcOffset = (absolute, relative, shift) => {
        let offset = null
        if (absolute == null || relative == null) {
            return offset;
        }
        else {
            offset = absolute - relative;
        }
        // offset -= Math.floor(offset / 360) * 360; // Wrap into range [0,360].
        return offset + shift;
    }

    let relativeListener = function (e) {
        let heading = - (e.alpha + e.beta * e.gamma / 90) + 360;
        heading -= Math.floor(heading / 360) * 360; // Wrap into range [0,360].
        if (heading != null && !isNaN(heading)) {
            relativeCompassHeading = heading;
            setHeading(heading);

            const deviceCorrectionFactor = -90
            let offset = calcOffset(absoluteCompassHeading, relativeCompassHeading, deviceCorrectionFactor);

            // let stability = calcStability(offset);

            if (offset != null) {
                setOrientationCompassOffset(offset);
                // window.removeEventListener("deviceorientation", relativeListener) ;
            }

        }
    }

    function removeListeners() {
        window.removeEventListener("deviceorientationabsolute", absoluteListener);
        window.removeEventListener("deviceorientation", webkitListener);
        window.removeEventListener("deviceorientation", relativeListener);
    }

    function addListeners() {
        window.addEventListener("deviceorientationabsolute", absoluteListener);
        window.addEventListener("deviceorientation", webkitListener);
        window.addEventListener("deviceorientation", relativeListener);

        if (!controlsOffsetInitialized) {
            setTimeout(() => {
                setControlsOffsetInitialized(true)
                removeListeners();
            }, calibrationTimeout)
        } else {
            removeListeners();
        }


    }


    if (typeof DeviceOrientationEvent.requestPermission === "function") {
        DeviceOrientationEvent.requestPermission()
            .then(function (response) {
                if (response == "granted") {
                    addListeners()
                } else
                    console.warn("Permission for DeviceMotionEvent not granted");
            });
    } else {
        addListeners()
    }


    return removeListeners;
}
