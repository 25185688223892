import React, { useRef, useEffect } from 'react';

const tresholdImageData = (imageData, threshold = 60) => {
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
        const red = data[i];
        const green = data[i + 1];
        const blue = data[i + 2];
        const alpha = data[i + 3];

        // Calculate the average grayscale value
        const gray = (red + green + blue) / 3;

        // Threshold the grayscale value
        const color = gray < threshold ? 0 : 255;

        // Set the pixel color to black or white
        data[i] = color; // red
        data[i + 1] = color; // green
        data[i + 2] = color; // blue
        // Keep the alpha value unchanged
    }

    return data;
}

const recolorWhiteInImgData = (imageData, color = "blue") => {
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
        const red = data[i];
        const green = data[i + 1];
        const blue = data[i + 2];
        const alpha = data[i + 3];

        if (red === 255 && green === 255 && blue === 255) {
            data[i] = 0;
            data[i + 1] = 0;
            data[i + 2] = 255;
        }
    }

    return data
}



export default function DisplayResultImage({ img = null, canvasKey = "panorama", tresholdImage = false, recolorWhite = false }) {
    const canvasRef = useRef(null);



    useEffect(() => {
        if (img === null || !img.width || !img.height) {
            return <div>no image</div>
        }
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');

            ctx.putImageData(img, 0, 0);

            const imageData = ctx.getImageData(0, 0, img.width, img.height);

            if (tresholdImage) {
                tresholdImageData(imageData);
            }

            if (recolorWhite) {
                recolorWhiteInImgData(imageData);
            }

            ctx.putImageData(imageData, 0, 0);
        }
    }, [img]);

    return <div style={{ width: "100%", height: "auto" }}>

        <canvas ref={canvasRef} key={`result-canvas-${canvasKey}`} style={{ width: '100%', height: 'auto'}} width={img.width} height={img.height} />
    </div >

}