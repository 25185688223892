const systemsToCompare = [
    {
        id: 1,
        name: 'FlexDesign 340',
        wattage: 340,
        price: 489,
        amortizationTime: 99,
        savingsCO2: 0,
        savingsEuro: 0,
        brand: "Sonnenrepublik",
        brandLogo: "https://sonnenrepublik.de/wp-content/uploads/2019/07/Logo.png",
        imageSrc: "https://sonnenrepublik.de/wp-content/uploads/2022/05/Unbenannt3-568x400.jpg",
        orderLink: "https://sonnenrepublik.de/shop-de/flexdesign-home-set",
        tiltRange: [90],
        easyInstallation: true,
        lightWeight: true
    },
    {
        id: 1,
        name: 'FlexDesign 450',
        wattage: 450,
        price: 589,
        amortizationTime: 99,
        savingsCO2: 0,
        savingsEuro: 0,
        brand: "Sonnenrepublik",
        brandLogo: "https://sonnenrepublik.de/wp-content/uploads/2019/07/Logo.png",
        imageSrc: "https://sonnenrepublik.de/wp-content/uploads/2022/05/Unbenannt3-568x400.jpg",
        orderLink: "https://sonnenrepublik.de/shop-de/flexdesign-home-set",
        tiltRange: [90],
        easyInstallation: true,
        lightWeight: true
    },
    {
        id: 1,
        name: 'FlexDesign 680',
        wattage: 680,
        price: 899,
        amortizationTime: 99,
        savingsCO2: 0,
        savingsEuro: 0,
        brand: "Sonnenrepublik",
        brandLogo: "https://sonnenrepublik.de/wp-content/uploads/2019/07/Logo.png",
        imageSrc: "https://sonnenrepublik.de/wp-content/uploads/2022/05/Unbenannt3-568x400.jpg",
        orderLink: "https://sonnenrepublik.de/shop-de/flexdesign-home-set",
        tiltRange: [90],
        easyInstallation: true,
        lightWeight: true
    },
    {
        id: 1,
        name: 'FlexDesign 900',
        wattage: 900,
        price: 1099,
        amortizationTime: 99,
        savingsCO2: 0,
        savingsEuro: 0,
        brand: "Sonnenrepublik",
        brandLogo: "https://sonnenrepublik.de/wp-content/uploads/2019/07/Logo.png",
        imageSrc: "https://sonnenrepublik.de/wp-content/uploads/2022/05/Unbenannt3-568x400.jpg",
        orderLink: "https://sonnenrepublik.de/shop-de/flexdesign-home-set",
        tiltRange: [90],
        easyInstallation: true,
        lightWeight: true
    },
    {
        id: 1,
        name: 'Home 380',
        wattage: 380,
        price: 229,
        amortizationTime: 99,
        savingsCO2: 0,
        savingsEuro: 0,
        brand: "Sonnenrepublik",
        brandLogo: "https://sonnenrepublik.de/wp-content/uploads/2019/07/Logo.png",
        imageSrc: "https://sonnenrepublik.de/wp-content/uploads/2019/12/Balkonmodul-web.jpg",
        orderLink: "https://sonnenrepublik.de/shop-de/home-set",
        tiltRange: [65, 90],
        easyInstallation: false,
        lightWeight: false
    },
    {
        id: 1,
        name: 'Home 720/2',
        wattage: 720,
        price: 379,
        amortizationTime: 99,
        savingsCO2: 0,
        savingsEuro: 0,
        brand: "Sonnenrepublik",
        brandLogo: "https://sonnenrepublik.de/wp-content/uploads/2019/07/Logo.png",
        imageSrc: "https://sonnenrepublik.de/wp-content/uploads/2019/12/Balkonmodul-web.jpg",
        orderLink: "https://sonnenrepublik.de/shop-de/home-set",
        tiltRange: [65, 90],
        easyInstallation: false,
        lightWeight: false
    }
]

export default systemsToCompare