import { calcTimeSinceStartup } from "./utils";

const apiToken = "pat3WGW15hwtsjQuC.2b652a2c514dfae480f5d7516170b9a070e5a72e5c95f0fce13c1b336215a94e";

const base = "appzReWaIcOtYCZ69";

function apiCall(data, globalState) {

    if (!globalState) {
        console.error("No globalState provided to apiCall");
        return;
    }

    if (!data) {
        console.error("No data provided to apiCall");
        return;
    }

    const table = globalState?.config?.logTable ? globalState.config.logTable : "betaUsageTrackingDev";
    const endpoint = `https://api.airtable.com/v0/${base}/${table}`;

    fetch(endpoint, {
        method: "PATCH",
        headers: {
            "Authorization": `Bearer ${apiToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            if (!response.ok) { // Checks if the response status code is not in the range 200-299
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json(); // You can process the response further if it's successful
        })
        .catch(error => {
            // if (error.response.code === 422) {
            //     console.error(`Unprocessable Content: ${JSON.stringify(data)}`);
            // } else {
            //     console.error(`Error in API call: ${error}`);
            // }
        });

}

export function updateUsageData(globalState = null) {

    const location = globalState?.location;

    const locationString = location ? ` ${location.latitude} ${location.longitude}` : "null";

    const stats = {
        lastAppStatus: globalState?.appStatus,
    }

    const data = {
        performUpsert: {
            fieldsToMergeOn: [
                "sessionId"
            ]
        },
        records: [
            {
                fields: {
                    sessionId: globalState.sessionId,
                    location: locationString,
                    statsJson: JSON.stringify(stats),
                    platform: JSON.stringify(globalState?.hostPlatform),
                    userActionLog: JSON.stringify(globalState?.userActionLog),
                    appVersion: globalState?.appVersion,
                    totalTimeInApp: calcTimeSinceStartup(globalState?.startTime),
                    // globalState: JSON.stringify(globalState),
                }
            }
        ]
    };

    apiCall(data, globalState);
}

// TODO inlude function for error logging

export function logAppExit(exitViaString, globalState) {

    const data = {
        performUpsert: {
            fieldsToMergeOn: [
                "sessionId"
            ]
        },
        records: [
            {
                fields: {
                    sessionId: globalState.sessionId,
                    appExitVia: exitViaString,
                    totalTimeInApp: calcTimeSinceStartup(globalState?.startTime),
                }
            }
        ]
    };

    apiCall(data, globalState);
}