const systemsToCompare = [
    {
        id: 1,
        name: 'FLEX 400/400',
        wattage: 400,
        price: 365,
        amortizationTime: 99,
        savingsCO2: 0,
        savingsEuro: 0,
        brand: "GreenSolar",
        brandLogo: "https://greensolar.de/wp-content/uploads/2023/01/green-solar-logo-2023-grau.png",
        imageSrc: "https://greensolar.de/wp-content/uploads/2024/01/20067-Balkonkraftwerk-FLEX-400400_NEU_04-1.jpg",
        orderLink: "https://greensolar.de/produkt/balkonkraftwerk-flex-400-400/?ref=2uwnzgvo",
        tiltRange: [90]
    },
    {
        id: 2,
        name: 'FLEX 800/800',
        wattage: 800,
        price: 662,
        amortizationTime: 99,
        savingsCO2: 0,
        savingsEuro: 0,
        brand: "GreenSolar",
        brandLogo: "https://greensolar.de/wp-content/uploads/2023/01/green-solar-logo-2023-grau.png",
        imageSrc: "https://greensolar.de/wp-content/uploads/2024/01/20067-Balkonkraftwerk-FLEX-400400_NEU_04-1.jpg",
        orderLink: "https://greensolar.de/produkt/balkonkraftwerk-flex-800-800/?ref=2uwnzgvo",
        tiltRange: [90]
    },
    {
        id: 3,
        name: '880/800 bifazial',
        wattage: 880,
        price: 564,
        amortizationTime: 99,
        savingsCO2: 0,
        savingsEuro: 0,
        brand: "GreenSolar",
        brandLogo: "https://greensolar.de/wp-content/uploads/2023/01/green-solar-logo-2023-grau.png",
        imageSrc: "https://greensolar.de/wp-content/uploads/2023/11/20309-Balkonkraftwerk-880800-bifazial_03.jpg",
        orderLink: "https://greensolar.de/produkt/balkonkraftwerk-880-800-bifazial/?ref=2uwnzgvo",
        tiltRange: [45, 90]
    }
]

export default systemsToCompare