import { Box, Button, Typography } from "@mui/material"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import ExplanatorySection from "./HelloScreenComponents/ExplanatorySection";
import LogoBox from "../utils/LogoBox";
import LanguageSwitch from "../utils/LanguageSwitch";
import LanguageSelector from "../utils/LanguageSelector.tsx";
import { useTranslation } from 'react-i18next';
import ByBesserAiFooter from "./HelloScreenComponents/ByBesserAiFooter";
import React from "react";
import Collapse from '@mui/material/Collapse';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';


export default function HelloScreen({ globalState }) {
    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState(false);
    const theme = useTheme(); // get the theme object

    const appendUserAction = (userAction) => {
        globalState.appendToUserActionLog({ screen: "HelloScreen", userAction: userAction});
    }

    const handleChange = () => {
        setExpanded(!expanded);
        appendUserAction("How does it work expanded")
    };

    return (
        <>
            <LogoBox />

            {/* <LanguageSwitch /> */}
            <LanguageSelector appendUserAction={appendUserAction}/>

            <Box m={2} sx={{ width: "90%", maxWidth: 500, paddingBottom: 10 }}>
                <Accordion expanded={expanded} onClick={handleChange} sx={{ border: 1, borderColor: theme.palette.primary.main }} >
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ justifyContent: 'center' }}
                    >
                        <Box flexGrow={1}>
                            <Typography sx={{ paddingRight: 3, paddingLeft: 3, textAlign: 'center' }}>
                                {t("How does it work")}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ExplanatorySection />
                    </AccordionDetails>
                </Accordion>

                <Button sx={{ width: '1em', margin: 10 }} variant="contained" onClick={() => {
                    globalState.setAppStatus("requestPermissions")
                    appendUserAction("startButtonClicked")
                }} >Start</Button>
            </Box >


            {!expanded && <>
                <ByBesserAiFooter visible={!expanded} appVersion={globalState.appVersion}/>
            </>
            }
        </>
    )
}
