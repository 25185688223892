import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Divider, Typography } from "@mui/material";
import SystemTile from "./ResultScreenComponents/SystemTile";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { ArrowDownward, EmojiObjects } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SavingsOverviewTile from "./ResultScreenComponents/SavingsOverviewTile";
import { useTheme } from "@mui/material/styles";
import Graph from "../M7Calculator/Graph";
import { SimpleTable } from "../M7Calculator/ResultTable";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DisplayResultImage from "../utils/DisplayResultImage";
import IconTextSection from "../Screens/HelloScreenComponents/IconTextSection";

// import BeforeAfterSegmentationDisplay from "../utils/BeforeAfterSegmentationDisplay";

export default function ResultScreen(props) {
  const { bestSystem, systems, graphData, yearlyBalanceValues, location, globalState } =
    props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [expandedSection, setExpandedSection] = React.useState(
    ["overview", "calculations", "systems"][0]
  );

  const amortizationTime = bestSystem.amortizationTime;

  let resultMessage = t("Congrats");
  let resultText = t("CongratsText");
  if (amortizationTime > 7) {
    resultMessage = t("LongRun");
    resultText = t("LongRunText");
  } else if (amortizationTime > 13) {
    resultMessage = t("NotWorth");
    resultText = t("NotWorthText");
  }

  const appendUserAction = (userAction) => {
    globalState.appendToUserActionLog({ screen: "ResultScreen", userAction: userAction });
  }

  const handleClick = (e) => {
    appendUserAction(`expanded ${e}`)
    if (expandedSection == e) {
      e = "overview";
    }
    setExpandedSection(e);
  };

  const electricityPrice = 0.33;

  useEffect(() => {
    const saveImg = (img, name) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.putImageData(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      const a = document.createElement("a");
      a.href = dataURL;
      a.download = name;
      a.click();
    };

    if (false) {
      console.log("saving images")
      // save the images
      saveImg(globalState.stitchedPanorama, "testPanorama.png");
      saveImg(globalState.segmentationMask, "testMask.png");
    }
  }, []);



  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    pauseOnFocus: true,
    swipeToSlide: true,
  };

  const sliderDivStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "80%"
  };

  // TODO modularize the sections
  return (
    <>
      <Accordion
        expanded={expandedSection == "overview"}
        sx={{ boxShadow: "none", border: "none", borderTop: "none" }}
        onClick={() => handleClick("overview")}
      >
        <AccordionSummary
          sx={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h5" align="center" style={{ paddingTop: 5 }}>
              {resultMessage}
            </Typography>
            <Typography
              variant="body2"
              align="center"
              style={{ paddingBottom: 20 }}
            >
              {resultText}
            </Typography>
          </div>
        </AccordionSummary>

        <SavingsOverviewTile systems={systems} />
      </Accordion>

      <Box m={2} sx={{ width: "90%", maxWidth: 500, paddingBottom: 10 }}>
        <Accordion
          expanded={expandedSection == "calculations"}
          sx={{ border: 1, borderColor: theme.palette.primary.main }}
          onClick={() => handleClick("calculations")}
        >
          <AccordionSummary
            sx={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography align="center" style={{ margin: 5 }}>
              {t("display explanation")}
            </Typography>
          </AccordionSummary>

          {/* section start */}
          {/* <Slider {...sliderSettings}> */}

            {/* Production Graphs */}
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
              <Typography align="center" style={{ paddingTop: 5 }}>
                {t("optimalSolarSystem", {
                  yearlyYield: yearlyBalanceValues.yearlyYield.toFixed(0),
                  electricityPrice: electricityPrice.toFixed(2),
                  electricityWorth: (
                    yearlyBalanceValues.yearlyYield * electricityPrice
                  ).toFixed(0),
                })}
              </Typography>
              {graphData.production && (
                <Graph
                  data={graphData.production}
                  options={{ scales: { x: { min: 0, max: 24 } } }}
                />
              )}
              <Typography align="center" style={{ margin: 5 }}>
                {t("calc explanation")}
              </Typography>
            </div>

            {false && <div>
              {/* <BeforeAfterSegmentationDisplay image={globalState.stitchedPanorama} segmentationMask={globalState.segmentationMask} /> */}

              <div style={sliderDivStyle}>
                <Typography align="center" style={{ margin: 5 }}>
                  {t("Panorama Image:")}
                </Typography>

                {globalState.stitchedPanorama && <DisplayResultImage img={globalState.stitchedPanorama} canvasKey="panorama" />}
              </div>


              <div style={sliderDivStyle}>
                <IconTextSection content={{ image: "brain.png", text: t("The AI detects the parts of clear sky (labelled blue) and obstructions (labelled black).") }} />
                <ArrowDownward fontSize="large" />
              </div>

              <Typography align="center" style={{ margin: 5 }}>
                {t("Segmentation:")}
              </Typography>

              <div style={sliderDivStyle}>
                <Typography align="center" style={{ margin: 5 }}>
                  {t("Segmentation:")}
                </Typography>
                {globalState.segmentationMask && <DisplayResultImage img={globalState.segmentationMask} canvasKey="mask" tresholdImage={true} recolorWhite={true} />}
              </div>
            </div>}


            {/* <div>
              maybe here one could visualize the weather impact
            </div> */}


          {/* </Slider> */}

          {/* section end */}
        </Accordion>
      </Box>

      <Box m={2} sx={{ width: "90%", maxWidth: 500, paddingBottom: 10 }}>
        {/* <SystemTile system={bestSystem} style='extended' /> */}

        <Accordion
          expanded={expandedSection == "systems"}
          sx={{ border: 1, borderColor: theme.palette.primary.light }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ justifyContent: "center" }}
            onClick={() => handleClick("systems")}
          >
            <Box flexGrow={1}>
              <Typography
                sx={{ paddingRight: 3, paddingLeft: 3, textAlign: "center" }}
              >
                {t("Recommended systems")}
              </Typography>
              {/* <ExpandMoreIcon /> */}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {systems.map((system, i) => (
              <React.Fragment key={i}>
                <SystemTile
                  system={system}
                  style="compact"
                  key={i}
                  location={location}
                  globalState={globalState}
                />
                {/* <Divider /> */}
              </React.Fragment>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}
