import { useState, useEffect, useRef } from 'react'
import ARCanvas from './components/M1CreatePanorama/AR/ARCanvas';
import FovCalculator from './components/M1CreatePanorama/FovCalculator';
import { calcTimeSinceStartup, loadTestData } from './components/utils/utils'
import { createPanorama } from './components/M1CreatePanorama/CreatePanorama';
import { Button } from '@mui/material';
import Segmenter from './components/M2Segmentation/Segmenter';
import MaskToShadowProfile from './components/utils/MaskToShadowProfile';
import SunFromWeatherApi, { loadTestWeatherData } from './components/M6WeatherAPI/SunFromWeatherApi';
import Calculator from './components/M7Calculator/Calculator';
import ModulePage from './components/utils/ModulePage';
import HelloScreen from './components/Screens/HelloScreen';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import ResultScreen from './components/Screens/ResultScreen';
import Box from '@mui/material/Box';
import {systemsToCompareSets} from './pvSystems/systemsToCompare';
import DisplayResultImage from './components/utils/DisplayResultImage';
import PanoramaImagesDisplay from './components/Screens/ProcessingScreenComponents/PanoramaImagesDisplay';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RequestPermissionsScreen from './components/Screens/RequestPermissionsScreen';
import './i18n/i18n';
import { useTranslation } from 'react-i18next';
import GoToBalconyScreen from './components/Screens/GoToBalconyScreen';
import { createEmptyShadowProfile } from "./components/M7Calculator/calculationScripts/yieldCalculation"
import { detectHostPlatform } from './components/utils/utils';
import config from './config/current';
import packageJson from '../package.json';

import { updateUsageData } from './components/utils/UsageTracking';
import { v4 as uuidv4 } from 'uuid';

const testImagesPath = [
  "test_images/Alfred/Grey-Snowy",
  "test_images/Alfred/Sunny-Bad-Resolution",
  "test_images/Alfred/Alfred-Kaestner-Sonne"
][2]



const verbose = false

const version = packageJson.version
const sessionId = uuidv4();
const hostPlatform = detectHostPlatform()

const startTime = new Date();

// dev
const dev = (process.env.REACT_APP_CONFIG_TO_USE === "dev")


export default function App() {
  const { t } = useTranslation();
  const [panoramaImages, setPanoramaImages] = useState([])
  const [stitchedPanorama, setStitchedPanorama] = useState(null);
  const [segmentationMask, setSegmentationMask] = useState(null);
  const [shadowProfile, setShadowProfile] = useState(null);
  // const [shadowProfile, setShadowProfile] = useState(dev ? createEmptyShadowProfile() : null);
  const [weatherProfile, setWeatherProfile] = useState(dev ? loadTestWeatherData() : null);
  const [openCVReady, setOpenCVReady] = useState(false);
  const [worker, setWorker] = useState(null);
  const [appStatus, setAppStatus] = useState("init");
  
  const [systemsToCompare, setSystemsToCompare] = useState(() => {
    let setsToUse = []
    config.systemsToCompare.map((setName) => {setsToUse.push(...systemsToCompareSets[setName])})
    return setsToUse});

  const [bestSystem, setBestSystem] = useState(systemsToCompare[0])
  // const [balconyAzimuth, setBalconyAzimuth] = useState(dev ? 180 : null)
  const [balconyAzimuth, setBalconyAzimuth] = useState(null)
  const [graphData, setGraphData] = useState({ "production": null, "consumption": null, "balance": null })
  const [yearlyBalanceValues, setYearlyBalanceValues] = useState(null)
  const [location, setLocation] = useState(null)
  const [cameraStream, setCameraStream] = useState(null)

  const [calcScreenTabStatus, setCalcScreenTabStatus] = useState(0);

  const [userActionLog, setUserActionLog] = useState([])

  // action is an object with keys: actionDescription, timeSinceStartup, screen
  const appendToUserActionLog = (action) => {

    action.timeSinceStartup = calcTimeSinceStartup(startTime)
      setUserActionLog([...userActionLog, action])
    updateUsageData(globalState)
  }

  const globalState = {
    sessionId,
    config,
    appVersion: version,
    hostPlatform,
    startTime,
    appendToUserActionLog,
    panoramaImages, setPanoramaImages,
    stitchedPanorama, setStitchedPanorama,
    segmentationMask, setSegmentationMask,
    shadowProfile, setShadowProfile,
    weatherProfile, setWeatherProfile,
    openCVReady, setOpenCVReady,
    worker, setWorker,
    appStatus, setAppStatus,
    systemsToCompare, setSystemsToCompare,
    bestSystem, setBestSystem,
    balconyAzimuth, setBalconyAzimuth,
    graphData, setGraphData,
    yearlyBalanceValues, setYearlyBalanceValues,
    location, setLocation,
    cameraStream, setCameraStream,
    calcScreenTabStatus, setCalcScreenTabStatus,
    userActionLog, appendToUserActionLog,
    updateUsageData
  }

  console.log("[App] globalState: ", globalState)

  // TODO these refs are probably not needed anymore
  const startScreen = useRef(null)
  const panoramaScreen = useRef(null)
  const calculatorScreen = useRef(null)
  const resultScreen = useRef(null)

  useEffect(() => {
    console.log("[App] started with REACT_APP_CONFIG_TO_USE: ", process.env.REACT_APP_CONFIG_TO_USE);
    updateUsageData(globalState)
  }, [])

  useEffect(() => {
    // init service worker and opencv
    const newWorker = new Worker("worker.js");
    newWorker.postMessage({ msg: 'loadOpenCV' });
    newWorker.addEventListener('message', (e) => {
      const { msg, payload } = e.data;

      if (msg === 'openCVLoaded') {
        console.log(payload)
        setOpenCVReady(true);
      }
    })

    setWorker(newWorker)

    if (config.loadTestDataOnInit) {
      loadTestDataCall()
    }
  }, [])

  const loadTestDataCall = () => {
    const initScreen = config.initialScreen
    
    loadTestData(testImagesPath).then((result) => {
      setPanoramaImages(result.panoramaImages)
      
      verbose && console.log("[App] loadTestDataCall result: ", result)
      
      if (result.balconyAzimuth) {
        setBalconyAzimuth(result.balconyAzimuth)
      }

      switch (initScreen) {
        case "showResults":
          if (result.testPanorama) {
            console.log("loading test panorama")
            setStitchedPanorama(result.testPanorama)
          }
          if (result.testMask) {

            setSegmentationMask(result.testMask)
          }
          if (result.yearlyBalanceValues) {
            setYearlyBalanceValues(result.yearlyBalanceValues)
          }
          break;
        default:
          console.error("Unknown initial screen: ", initScreen)
      }

      setAppStatus(initScreen)
    })
  }


  useEffect(() => {
    verbose && console.log("[App] weatherProfile changed: ", weatherProfile);
  }, [weatherProfile])


  const appStatusDisplay = () => {
    let text = ""
    console.log("[App] appStatus: ", appStatus)
    switch (appStatus) {
      case "initializing":
        text = "Starte App..."
        break;
      case "requestPermissions":
        text = "requestPermissions..."
        break;
      case "takePanoramaImages":
        text = "Nimm die Bilder auf..."
        break;
      case "loadingTestData":
        text = "Lade Testdaten..."
        break;
      case "readyForProcessing":
        text = t("readyForProcessing")
        break;
      case "creatingPanorama":
        text = t("creatingPanorama")
        break;
      case "loadingAI":
        text = t("loadingAI")
        break;
      case "segmenting":
        text = t("segmenting")
        break;
      case "loadingWeather":
        text = t("loadingWeather")
        break;
      case "calculatingBestSystem":
        text = t("calculatingBestSystem")
        break;
      case "resultsReady":
        text = t("resultsReady")
        break;
      default:
        return <></>
    }
    return <>
      {appStatus !== "readyForProcessing" && appStatus !== "resultsReady" &&
        <img src="loading.gif" alt="loading" />
      }
      <Typography variant="h5" component="h2" gutterBottom>
        {text}
      </Typography>
      {appStatus === "resultsReady" &&
        <Button variant="contained" color="primary" sx={{ padding: 3, margin: 10 }} onClick={() => { setAppStatus("showResults") }}>{t("showResults")}</Button>
      }
    </>
  }

  // useEffect(() => {
  //   startScreen.current.scrollIntoView({ behavior: 'smooth' });
  //   // document.body.style.overflow = 'hidden';

  //   // return () => {
  //   //   document.body.style.overflow = 'unset';
  //   // };
  // }, []);


  useEffect(() => {
    if (appStatus === "loadingTestData") loadTestDataCall();
    if (appStatus === "creatingPanorama") createPanorama(panoramaImages, worker, setStitchedPanorama, setAppStatus);
    updateUsageData(sessionId, globalState);

  }, [appStatus]);

  useEffect(() => {
    verbose && console.log("[App] bestSystem: ", bestSystem);
  }, [bestSystem]);

  return (<>
    <ThemeProvider theme={theme}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

        {(appStatus === "init") &&
          <ModulePage reference={startScreen} height="110vh">
            <HelloScreen globalState={globalState} />
          </ModulePage>
        }

        {(appStatus === "requestPermissions") &&
          <ModulePage reference={startScreen}>
            <RequestPermissionsScreen globalState={globalState}/>
          </ModulePage>
        }

        {(appStatus === "goToBalcony") &&
          <ModulePage reference={startScreen}>
            <GoToBalconyScreen globalState={globalState} />
          </ModulePage>
        }

        {!stitchedPanorama && (appStatus === "takePanoramaImages") &&
          <ModulePage reference={panoramaScreen} height="100vh">
            {/* <div style={{ height: "80vh" }}> */}
            <ARCanvas globalState={globalState}/>
            {/* </div> */}
          </ModulePage>
        }

        {(appStatus !== "showResults" && appStatus !== "init" && appStatus !== "takePanoramaImages" && appStatus !== "requestPermissions" && appStatus !== "goToBalcony") &&
          // {/* {(appStatus !== "resultsReady" && appStatus !== "init") && */}
          <ModulePage reference={calculatorScreen} height={"auto"}>
            {/* <ProcessingScreen appStatus={appStatus} /> */}

            {appStatusDisplay()}

            {appStatus === "readyForProcessing" &&
              <Button variant="contained" color="primary" sx={{ padding: 3, margin: 10 }} onClick={() => {
                setAppStatus("creatingPanorama");
              }
              }>{t("Next")}</Button>
            }

            <Accordion sx={{ maxWidth: "90%", display: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box flexGrow={1}>
                  <Typography sx={{ paddingRight: 3, paddingLeft: 3, textAlign: 'center' }}>
                    Details
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>

                <Tabs value={calcScreenTabStatus} onChange={(_, n) => setCalcScreenTabStatus(n)} sx={{ marginBottom: 5 }} variant="scrollable"
                  scrollButtons="auto">
                  <Tab label="Panorama" />
                  <Tab label="Segmentierung" />
                  <Tab label="Projektion" />
                  <Tab label="Wetter" />
                  <Tab label="Berechung" />
                </Tabs>

                <Box hidden={calcScreenTabStatus !== 0}>
                  {panoramaImages && !stitchedPanorama &&
                    <PanoramaImagesDisplay panoramaImages={panoramaImages} />}

                  {stitchedPanorama && <DisplayResultImage img={stitchedPanorama} canvasKey="panorama" />}
                </Box>


                <Box hidden={calcScreenTabStatus !== 1}>
                  {stitchedPanorama && <div style={{ width: "100%" }}>
                    <Segmenter imageToPredict={stitchedPanorama} maskTarget={setSegmentationMask} setAppStatus={setAppStatus} />
                  </div>}
                </Box>

                <Box hidden={calcScreenTabStatus !== 2}>
                  {segmentationMask && <div style={{ width: "100%" }}>
                    <MaskToShadowProfile mask={segmentationMask} panoramaImages={panoramaImages} setShadowProfile={setShadowProfile} setAppStatus={setAppStatus} />
                  </div>}
                </Box>

                <Box hidden={calcScreenTabStatus !== 3}>
                  {shadowProfile && <div style={{ width: "100%" }}>
                    {/* currently using Berlin data only */}
                    <SunFromWeatherApi setWeatherProfile={setWeatherProfile} setAppStatus={setAppStatus} />
                  </div>}
                </Box>

                <Box hidden={calcScreenTabStatus !== 4}>
                  {shadowProfile && weatherProfile && <div style={{ width: "100%" }}>
                    <Calculator inputShadowProfile={shadowProfile} inputWeatherProfile={weatherProfile}
                      setAppStatus={setAppStatus} systemsToCompare={systemsToCompare}
                      setSystemsToCompare={setSystemsToCompare} bestSystem={bestSystem} setBestSystem={setBestSystem} balconyAzimuth={balconyAzimuth} graphData={graphData} setGraphData={setGraphData} yearlyBalanceValues={yearlyBalanceValues} setYearlyBalanceValues={setYearlyBalanceValues} />
                  </div>}
                </Box>

              </AccordionDetails>
            </Accordion>
          </ModulePage>
        }

        {(appStatus === "showResults") &&
          <ModulePage reference={resultScreen} height="auto">
            <ResultScreen bestSystem={bestSystem} systems={systemsToCompare} graphData={graphData} yearlyBalanceValues={yearlyBalanceValues} location={location} globalState={globalState}/>
          </ModulePage>
        }

        {/* <div>
        <Button variant="contained" color="primary" onClick={loadTestDataCall}>load test images</Button>
      </
      div> */}


        {/* {openCVReady && worker && panoramaImages && (panoramaImages.length > 2) && <div style={{ width: "100%" }}>
        <FovCalculator panoramaImages={panoramaImages} worker={worker} />
        <CreatePanorama panoramaImages={panoramaImages} worker={worker} stitchedPanorama={stitchedPanorama} setStitchedPanorama={setStitchedPanorama} />
      </div>} */}





      </div>
    </ThemeProvider >
  </>
  )
}
