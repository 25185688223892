import { Box, Button, Typography } from "@mui/material"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExplanatorySection from "./HelloScreenComponents/ExplanatorySection";
import Alert from '@mui/material/Alert';

import { Grid } from "@mui/material";
import IconTextSection from "./HelloScreenComponents/IconTextSection";
import { useTranslation } from 'react-i18next';
import LogoBox from "../utils/LogoBox";

import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

const nextStatus = "goToBalcony"
const verbose = false

export default function RequestPermissionsScreen({ globalState }) {
    const { setAppStatus, setLocation, setCameraStream } = globalState;

    const appendUserAction = (userAction) => {
        globalState.appendToUserActionLog({ screen: "RequestPermissionsScreen", userAction: userAction });
    }

    const theme = useTheme();
    const { t } = useTranslation();
    const [locationAccessGranted, setLocationAccessGranted] = useState(null)
    const [deviceOrientationAccessGranted, setDeviceOrientationAccessGranted] = useState(null)
    const [cameraAccessGranted, setCameraAccessGranted] = useState(null)

    const grantedColor = "green"
    const deniedColor = "red"
    const promptColor = theme.palette.primary.main

    const getColor = (accessState) => {
        if (accessState === null) {
            return promptColor
        } else if (accessState) {
            return grantedColor
        } else {
            return deniedColor
        }
    }

    const orientationAccess = {
        "text": t("Orientation access"), "image": <ScreenRotationIcon sx={{ color: getColor(deviceOrientationAccessGranted), fontSize: 50 }} />
    }
    const locationAccess = {
        "text": t("Location access"), "image": <MyLocationIcon sx={{ color: getColor(locationAccessGranted), fontSize: 50 }} />
    }
    const cameraAccess = {
        "text": t("Camera access"), "image": <CameraAltIcon sx={{ color: getColor(cameraAccessGranted), fontSize: 50 }} />
    }

    const requestPermissions = async () => {
        appendUserAction("requestPermissions")

        if (!deviceOrientationAccessGranted) {
            await requestDeviceOrientationAccess().catch(console.error)
        }
        if (!locationAccessGranted) {
            await requestLocationAccess().catch(console.error)
        }
        if (!cameraAccessGranted) {
            await requestCameraAccess()
        }
    }

    const requestLocationAccess = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        // Handle successful geolocation access
                        const { latitude, longitude } = position.coords;
                        setLocation({ latitude, longitude })
                        setLocationAccessGranted(true)
                        resolve();
                    },
                    (error) => {
                        // Handle geolocation access error
                        console.warn("Error requesting geolocation access:", error);
                        reject(error);
                        setLocationAccessGranted(false)
                    }
                );
            } else {
                // Geolocation is not supported by the browser
                console.error("Geolocation is not supported");
                reject(new Error("Geolocation is not supported"));
            }
        });
    };

    const requestCameraAccess = () => {
        navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment", width: { min: 320, ideal: 1280, max: 1920 } } })
            .then(function (stream) {
                setCameraAccessGranted(true)
                setCameraStream(stream)
                /* use the stream */
            })
            .catch(function (err) {
                if (err.name === 'OverconstrainedError') {
                    console.error("OverconstrainedError:", err.constraint)
                }
                console.warn("Error requesting camera access:", err);
                setCameraAccessGranted(false)
            });
    }

    const requestDeviceOrientationAccess = () => {
        return new Promise((resolve, reject) => {
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            console.log("isSafari:", isSafari)

            if (isSafari) {
                DeviceOrientationEvent.requestPermission()
                    .then(permissionState => {
                        if (permissionState === 'granted') {
                            setDeviceOrientationAccessGranted(true);
                            console.log("Device orientation access has already been granted");
                        } else if (permissionState === 'prompt') {
                            console.log("User will be prompted for device orientation access");
                        } else if (permissionState === 'denied') {
                            console.log("Device orientation access has been denied");
                        }
                    })
                    .catch(console.error);
            } else {
                setDeviceOrientationAccessGranted(true);
                console.log("Device orientation access not necessary to be requested");
            }
            //     } else if (navigator.geolocation) {
            //     navigator.geolocation.getCurrentPosition(
            //         (position) => {
            //             // Handle successful geolocation access
            //             const { latitude, longitude } = position.coords;
            //             console.log("Latitude:", latitude);
            //             console.log("Longitude:", longitude);
            //             setDeviceOrientationAccessGranted(true);
            //             resolve();
            //         },
            //         (error) => {
            //             // Handle geolocation access error
            //             console.error("Error requesting geolocation access:", error);
            //             if (error.code === error.PERMISSION_DENIED) {
            //                 console.warn("User denied the request for Geolocation.");
            //             }
            //             reject(error);
            //         }
            //     );
            // } else {
            //     // Geolocation is not supported by the browser
            //     console.error("Geolocation is not supported");
            //     reject(new Error("Geolocation is not supported"));
            // }
        });
    }

    useEffect(() => {
        if (locationAccessGranted && cameraAccessGranted && deviceOrientationAccessGranted) {
            setAppStatus(nextStatus)
        } else if (locationAccessGranted === false || cameraAccessGranted === false || deviceOrientationAccessGranted === false) {
        }

    }
        , [locationAccessGranted, cameraAccessGranted, deviceOrientationAccessGranted])

    // initial check for permissions
    useEffect(() => {
        // check if permissions.query is supported, if not, log an error and skip check
        if (!navigator.permissions) {
            console.error("navigator.permissions is not supported");
            return;
        }
        navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
            if (result.state === 'granted') {
                requestLocationAccess()
                setLocationAccessGranted(true)
                console.log("geolocation access has already been granted")
            } else if (result.state === 'prompt') {
                console.log("user will be prompted for geolocation access")
            } else if (result.state === 'denied') {
                console.log("geolocation access has been denied")
                alert("Location access has been denied")
            }
        });
        navigator.permissions.query({ name: 'camera' }).then(function (result) {
            if (result.state === 'granted') {
                setCameraAccessGranted(true)
                console.log("Camera access has already been granted");
            } else if (result.state === 'prompt') {
                console.log("User will be prompted for camera access");
            } else if (result.state === 'denied') {
                console.log("Camera access has been denied");
                alert("Camera access has been denied")
            }
        });
        if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
            DeviceOrientationEvent.requestPermission()
                .then(permissionState => {
                    if (permissionState === 'granted') {
                        setDeviceOrientationAccessGranted(true);
                        console.log("Device orientation access has already been granted");
                    } else if (permissionState === 'prompt') {
                        console.log("User will be prompted for device orientation access");
                    } else if (permissionState === 'denied') {
                        console.log("Device orientation access has been denied");
                    }
                })
                .catch(console.error);
        } else {
            console.log("Device orientation access permissions are not supported");
            window.addEventListener('deviceorientation', function (event) {
                verbose && console.log("Alpha: " + event.alpha, "Beta: " + event.beta, "Gamma: " + event.gamma);
                if (event.alpha === null) {
                    console.warn("Device orientation not available on the device");
                } else {
                    setDeviceOrientationAccessGranted(true);
                }
            });
        }

    }, [])

    function refreshPage() {
        window.location.reload();
    }

    return (

        <>

            <LogoBox />

            <Box m={2} sx={{ width: "100%", maxWidth: 500, paddingBottom: 10 }}>


                <Accordion sx={{ boxShadow: 'none', border: 'none' }} defaultExpanded>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ justifyContent: 'center' }}
                    >
                        <Box flexGrow={1} alignItems={"center"}>

                            <Typography sx={{ paddingRight: 3, paddingLeft: 3, textAlign: 'center' }}>
                                {t("First we need some permissions:")}
                            </Typography>

                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <IconTextSection content={orientationAccess} />
                        <IconTextSection content={locationAccess} />
                        <IconTextSection content={cameraAccess} />
                    </AccordionDetails>
                </Accordion>

                {(locationAccessGranted === false || cameraAccessGranted === false || deviceOrientationAccessGranted === false) && <>
                    <Alert severity="error" style={{ alignItems: "center", marginBottom: '2em' }}>{t("permissions blocked warning")}</Alert>
                    <Button variant="contained" onClick={refreshPage} >{t("reload")}</Button>
                </>
                }

                {!(locationAccessGranted === false || cameraAccessGranted === false || deviceOrientationAccessGranted === false) &&
                    <Button variant="contained" onClick={requestPermissions}>{t("request next")}</Button>
                }

            </Box >

            {/* <Box m={2} >
            </Box> */}
        </>
        // </Box >
    )
}