import { Camera } from "@mui/icons-material";
import { useThree, useFrame } from "@react-three/fiber";
import * as THREE from "three";
import {
    Shape,
    ShapeGeometry,
    MeshBasicMaterial,
    Mesh,
    RingGeometry,
} from "three";
import { Canvas } from "@react-three/fiber";
import { useEffect, useRef } from "react";

import { degToRad, radToDeg, getViewDirection } from "./utils";

const verbose = false;

const testRequestedSnapshots = [
    { azimuth: 90, altitude: 0, roll: 0, taken: false },
];

const calcPointingDirAndDistance = (snapshot, cameraRef) => {
    const viewDir = getViewDirection(cameraRef);

    let deltaAzimuth = ((snapshot.azimuth - viewDir.azimuth + 180) % 360) - 180;

    // Correct deltaAzimuth if it's outside the -180 to 180 range
    if (deltaAzimuth > 180) {
        deltaAzimuth -= 360;
    } else if (deltaAzimuth < -180) {
        deltaAzimuth += 360;
    }

    const delta = {
        azimuth: deltaAzimuth,
        altitude: snapshot.altitude - viewDir.altitude,
        roll: -viewDir.roll,
    };

    verbose &&
        console.log(
            "snapshot azimuth:",
            snapshot.azimuth,
            "viewDir azimuth:",
            viewDir.azimuth,
            "delta azimuth:",
            delta.azimuth
        );

    const pointingDir =
        radToDeg(Math.atan2(delta.altitude, delta.azimuth)) - viewDir.roll;
    const angleDistance = Math.sqrt(delta.azimuth ** 2 + delta.altitude ** 2);

    return { pointingDir, angleDistance };
};

export default function SeekerOverlayThree({
    color = "red",
    size = 70,
    requestedSnapshots = testRequestedSnapshots,
    mainCameraRef = null,
}) {
    const { scene } = useThree();
    const arrowMeshes = useRef({});
    const ringMeshRef = useRef();

    useFrame(() => {
        if (mainCameraRef.current) {
            // Remove all existing arrows from the scene
            for (const key in arrowMeshes.current) {
                scene.remove(arrowMeshes.current[key]);
                arrowMeshes.current[key].geometry.dispose();
                arrowMeshes.current[key].material.dispose();
            }
            arrowMeshes.current = {};

            requestedSnapshots.forEach((snapshot, index) => {
                const { pointingDir, angleDistance } = calcPointingDirAndDistance(
                    snapshot,
                    mainCameraRef
                );

                if (!snapshot.taken) {
                    overlayArrow(pointingDir, angleDistance, index);
                }
            });
        }
    });

    

    const overlayArrow = (pointingDirDeg = 30, angleDistance = 1, index = 0) => {
        const arrowScale = 8; // Overall scale of the arrow, adjust if needed
        const arrowBodyLength = 4 * arrowScale; // Length of the arrow body
        const arrowBodyWidth = 5 * arrowScale; // Width of the arrow body
        const arrowHeadLength = 5 * arrowScale; // Length of the arrow head
        const arrowHeadWidth = 10 * arrowScale; // Width of the arrow head
    
        // Create a new Arrow geometry
        const shape = new Shape();
        // Start at the top of the arrow head
        shape.moveTo(0, arrowBodyLength + arrowHeadLength);
        // Draw the right side of the arrow head
        shape.lineTo(arrowHeadWidth / 2, arrowBodyLength);
        // Draw the right side of the arrow body
        shape.lineTo(arrowBodyWidth / 2, arrowBodyLength);
        shape.lineTo(arrowBodyWidth / 2, 0);
        // Draw the bottom of the arrow body
        shape.lineTo(-arrowBodyWidth / 2, 0);
        // Draw the left side of the arrow body
        shape.lineTo(-arrowBodyWidth / 2, arrowBodyLength);
        // Draw the left side of the arrow head
        shape.lineTo(-arrowHeadWidth / 2, arrowBodyLength);
        // Close the shape
        shape.lineTo(0, arrowBodyLength + arrowHeadLength);

        const geometry = new ShapeGeometry(shape);

        // Step 2: Create a new Mesh
        const belowAngleFading = 5;
        const material = new MeshBasicMaterial({
            color: color,
            transparent: true,
            opacity: 1 - belowAngleFading / angleDistance,
        });
        const arrow = new Mesh(geometry, material);

        // Step 3: Add the Mesh to the scene
        scene.add(arrow);
        arrowMeshes.current[index] = arrow;

        // Step 4: Update the position and rotation of the arrow
        const distanceToOrigin = size + 5 ;
        const x = distanceToOrigin * Math.cos(degToRad(pointingDirDeg));
        const y = distanceToOrigin * Math.sin(degToRad(pointingDirDeg));
        arrow.position.set(x, y, -5);
        arrow.rotation.set(0, 0, degToRad(pointingDirDeg - 90));

        return () => {
            if (arrowMeshes.current[index]) {
                scene.remove(arrowMeshes.current[index]);
            }
        };
    };

    const overlayRing = () => {
        // Create a ring
        const width = 10;
        const outerRadius = size;
        const innerRadius = outerRadius - width;
        const thetaSegments = 128; // Number of segments. A higher number means the ring will be more round
        const geometry = new RingGeometry(innerRadius, outerRadius, thetaSegments);

        // Step 2: Create a new Mesh
        const material = new MeshBasicMaterial({ color: color });
        const ring = new Mesh(geometry, material);

        // Step 3: Add the Mesh to the scene
        scene.add(ring);
        ringMeshRef.current = ring;

        // Step 4: Update the position and rotation of the ring
        ring.position.set(0, 0, -5);
        ring.rotation.set(0, 0, 0);

        return () => {
            if (ringMeshRef.current) {
                scene.remove(ringMeshRef.current.ring);
            }
        };
    };

    useEffect(() => {
        // overlayArrow()
        overlayRing();
    }, []);

    return null;
}
