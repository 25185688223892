import React from 'react'
import { Button, Typography, Box } from '@mui/material'
import LanguageSwitch from '../utils/LanguageSwitch'
import { useTranslation } from 'react-i18next'


const nextStatus = "takePanoramaImages"

export default function GoToBalconyScreen({ globalState }) {
    const { setAppStatus } = globalState;
    const { t } = useTranslation();

    const appendUserAction = (userAction) => {
        globalState.appendToUserActionLog({ screen: "GoToBalconyScreen", userAction: userAction});
    }

    return (
        <>

            <Typography sx={{ paddingRight: 3, paddingLeft: 3, textAlign: 'center' }}>
                {t("Go to balcony")}
            </Typography>

            <img src={"gotobalcony.png"} style={{ width: "80%", maxHeight: '300px', objectFit: "contain" }} />

            <Box m={2} sx={{ width: "90%", maxWidth: 500, paddingBottom: 10 }}>

                <Button sx={{ margin: 10, whiteSpace: 'nowrap' }} variant="contained" onClick={() => {
                    appendUserAction("I am there clicked")
                    setAppStatus("takePanoramaImages")
                }} >{t("I am there")}</Button>
            </Box >

            {/* <LanguageSwitch /> */}
        </>
    )
}