// fr.js

export default {

    "Language Select": "Changer en français",
    
    "How does it work": "Comment ça marche ?",
    
    "panoramaExplanation": "Scannez le ciel depuis votre balcon. Les capteurs de votre téléphone fournissent des données précises sur la position et l'orientation.",
    
    "calculateExplanation": "L'IA calcule automatiquement la meilleure orientation et la taille du système. Les données météorologiques précises sont récupérées en fonction de votre position.",
    
    "resultsExplanation": "Consultez immédiatement les économies de CO₂ et d'argent que vous pouvez réaliser avec une petite installation personnelle.",
    
    "First we need some permissions:": "Tout d'abord, nous avons besoin de quelques autorisations :",
    
    "Orientation access": "Nous avons besoin de l'orientation de votre appareil pour créer le panorama.",
    
    "Location access": "Nous avons besoin de votre emplacement pour calculer l'irradiation solaire locale, la météo, etc.",
    
    "Camera access": "Nous avons besoin de votre caméra pour prendre des photos du ciel.",
    
    "Next": "Suivant",
    
    "Use Test Data": "Utiliser des données de test",
    
    "readyForProcessing": "Prêt à être traité",
    
    "creatingPanorama": "Création du panorama en cours...",
    
    "loadingAI": "Chargement de l'IA...",
    
    "segmenting": "Analyse des ombres...",
    
    "loadingWeather": "Chargement des données météo locales...",
    
    "calculatingBestSystem": "Calcul du meilleur système en cours...",
    
    "resultsReady": "Prêt !",
    
    "showResults": "Afficher les résultats",
    
    "Congrats": "Félicitations !",
    
    "CongratsText": "Une installation de balcon solaire est rentable pour vous :",
    
    "Other Systems": "Autres systèmes",
    
    "Order Now": "Commander maintenant",
    
    "Order": "Commander",
    
    "years": " ans",
    
    "co2savings tooltip": "C'est la quantité de CO₂ que votre installation économisera chaque année ! Pour comparaison : un arbre absorbe environ 25 kg de CO₂ par an.",
    
    "moneysavings tooltip": "C'est le montant d'argent que vous économiserez chaque année !",
    
    "paysoffafter tooltip": `C"est le temps nécessaire pour que votre installation s"amortise. Durée de vie typique : plus de 30 ans !`,
    
    "returns tooltip": `C"est le taux de retour sur votre investissement. Pour comparaison : un compte d"épargne typique offre seulement 4 %.`,
    
    "Powered by": "Propulsée par ",
    
    "init panorama": "Lancement du créateur de panorama...",
    
    "calibrate compass": "Bougez et tournez votre téléphone en forme de 8 pour calibrer la boussole.",
    "calibrate compass done": "Calibrage de la boussole terminé !",
    
    "Go to balcony": "Allez sur votre balcon...",
    
    "I am there": "J'y suis !",
    
    "Get ready 1": "À l'étape suivante, nous scannerons le ciel.",
    
    "Get ready 2": "Placez-vous à l'avant de votre balcon et regardez droit devant.",
    
    "look at arrows": "Tournez-vous dans la direction de la flèche et cherchez le symbole de l'appareil photo ",
    
    "positions pending": "Position(s) en attente de vérification",
    
    "permissions blocked warning": "Une des autorisations a été refusée. L'application ne peut pas fonctionner sans elle. Vous pouvez essayer de recharger la page ou vérifier les paramètres de votre navigateur pour accorder les autorisations.",
    
    "reload": "Recharger",
    
    "request next": "Demander les autorisations suivantes",
    
    "Saves": "Économies",
    
    "Return on Investment": "Retour sur investissement",
    
    "kg / a": "kg / an",
    
    "€ / a": "€ / an",
    
    "Pays off in": "Se rembourse en",
    
    "LongRun": "Très rentable !",
    
    "LongRunText": "Votre balcon solaire s'ammortit rapidement. En plus, il fait du bien pour l'environnement immédiatement !",
    
    "NotWorth": "Un peu rentable.",
    
    "NotWorthText": "Votre balcon solaire s'ammortit lentement. Cependant, il fait du bien pour l'environnement immédiatement !",
    
    "winter": "Hiver",
    
    "summer": "Été",
    
    "spring/autumn": "Printemps/Automne",
    
    "hour": "Heure",
    
    "production": "Production en %",
    
    "visit site": "Visitez le site Web",
    
    "optimalSolarSystem": "Le système solaire optimal pour votre balcon produit {{yearlyYield}} kWh d'électricité par an. À un prix de {{electricityPrice}} € par kWh, cette électricité vaut {{electricityWorth}} €.",
    
    "calc explanation": "L'IA analyse la vue du ciel depuis votre balcon. En combinant ces données avec les données météorologiques locales, il est possible de calculer le profil de production précis et de le comparer à un profil de consommation électrique typique d'un ménage français (2000 kWh par an).",
    
    "display explanation": "Afficher l'explication",
    
    "Recommended systems": "Systèmes recommandés",
    
    // autres traductions...
    
    };