import './Calculator.css';
import React, { useEffect, useState } from 'react';
import Graph from './Graph';
import { getYearlyPowerProfile } from './calculationScripts/yieldCalculation'
import { createEmptyShadowProfile } from "./calculationScripts/yieldCalculation"
import InputSection from './InputSection';
import ResultTable from './ResultTable';
import SystemTable from './SystemTable';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// eslint-disable-next-line import/no-webpack-loader-syntax
import calculatorWorker from 'worker-loader!./calculatorWorker.js';
import { useTranslation } from 'react-i18next';

const verbose = true




const Calculator = ({ inputShadowProfile, inputWeatherProfile, setAppStatus, systemsToCompare, setSystemsToCompare, bestSystem, setBestSystem, balconyAzimuth = null, graphData, setGraphData, yearlyBalanceValues, setYearlyBalanceValues }) => {
    const [t, i18n] = useTranslation();
    verbose && console.log("[Calculator] balconyAzimuth provided: ", balconyAzimuth)

    const azimuthRangeToCheck = balconyAzimuth ? [balconyAzimuth, balconyAzimuth] : [0, 360];

    const [orientation, setOrientation] = useState({ "azimuth": balconyAzimuth, "tilt": 45 })
    const [location, setLocation] = useState({ "longitude": 13.3888599, "latitude": 52.5170365 })
    // const [shadowProfile, setShadowProfile] = useState(createEmptyShadowProfile())
    const [shadowProfile, setShadowProfile] = useState(inputShadowProfile ? inputShadowProfile : createEmptyShadowProfile())
    const [wattage, setWattage] = useState(600)
    const [weatherProfile, setWeatherProfile] = useState(inputWeatherProfile)
    const [calculatorStatus, setCalculatorStatus] = useState(["idle", "calculating", "bestSystemFound", "yearlyPowerProfileCalculated", "balanceCalculated", "resultsReady"][0])
    // const [systemsToCompare, setSystemsToCompare] = useState(defaultSystemsToCompare)

    const [solarYieldProfile, setSolarYieldProfile] = useState(null)
    const [consumptionProfile, setConsumptionProfile] = useState(null)
    const [totalConsumption, setTotalConsumption] = useState(null)
    const [balanceProfile, setBalanceProfile] = useState(null)
    const [worker, setWorker] = useState(null);
    const [useLocalWeatherInCalculation, setUseLocalWeatherInCalculation] = useState(true)


    const inputValues = { orientation, location, shadowProfile, wattage, useLocalWeatherInCalculation }

    const setAzimuth = (azimuth) => {
        setOrientation({ "azimuth": azimuth, "tilt": orientation.tilt })
    }
    const setTilt = (tilt) => {
        setOrientation({ "azimuth": orientation.azimuth, "tilt": tilt })
    }
    const setLongitude = (longitude) => {
        setLocation({ "longitude": longitude, "latitude": location.latitude })
    }
    const setLatitude = (latitude) => {
        setLocation({ "longitude": location.longitude, "latitude": latitude })
    }

    const setInputValues = { setAzimuth, setTilt, setLatitude, setLongitude, setShadowProfile, setWattage, setUseLocalWeatherInCalculation }

    if (systemsToCompare.length === 0 || !systemsToCompare) {
        console.error("[Calculator] No systems to compare provided")
    }

    // initialize worker
    useEffect(() => {
        const newWorker = new calculatorWorker();
        newWorker.postMessage({ msg: 'test', payload: "testpayload" });
        newWorker.addEventListener('message', (e) => {
            const { msg, payload } = e.data;
        })

        setWorker(newWorker)

    }, [])

    // worker.postMessage({ msg: 'predict', payload: { imageToPredict, useBinaryMask, threshold } });


    const findBestSystem = async () => {
        setAppStatus("calculatingBestSystem")
        setCalculatorStatus("calculating")
        worker.postMessage({
            msg: 'findBestSystem', payload: {
                location, shadowProfile, consumptionProfile, systemsToCompare, weatherProfile, azimuthRangeToCheck
            }
        })
        worker.addEventListener('message', (e) => {
            const { msg, payload } = e.data;
            if (msg === "bestSystemFound") {
                verbose && console.log("[calculatorWorker] response: ", msg, payload)
                setBestSystem(payload.bestSystem)
                setOrientation(payload.bestOrientation)
                setWattage(payload.bestSystem.wattage)
                setSystemsToCompare(payload.evaluatedSystems)
                setCalculatorStatus("bestSystemFound")

            }
        })
    }


    const calculateBalance = () => {
        setCalculatorStatus("calculating")
        worker.postMessage({
            msg: 'calculateBalance', payload: { solarYieldProfile, consumptionProfile, wattage }
        })
        worker.addEventListener('message', (e) => {
            const { msg, payload } = e.data;
            if (msg === "balanceCalculated") {
                verbose && console.log("[calculatorWorker] response: ", msg, payload)
                setBalanceProfile(payload.balanceProfile)
                setYearlyBalanceValues(payload.yearlyBalanceValues)
                setCalculatorStatus("balanceCalculated")
            }
        })
    }

    // calculateYearlyProfile
    const calculateYearlyProfile = async () => {

        // verbose && console.log("[Calculator] [calculateYearlyProfile] ShadowProfile: ", shadowProfile);
        setCalculatorStatus("calculating")
        worker.postMessage({
            msg: 'calculateYearlyPowerProfile', payload: { orientation, location, shadowProfile, weatherProfile, useLocalWeatherInCalculation }
        })
        worker.addEventListener('message', (e) => {
            const { msg, payload } = e.data;
            if (msg === "yearlyPowerProfileCalculated") {
                console.log("[calculatorWorker] response: ", msg, payload)
                setSolarYieldProfile(payload)
                setCalculatorStatus("yearlyPowerProfileCalculated")
            }
        })

    }


    const refreshGraphData = () => {
        const getLabel = (i) => {
            switch (i) {
                case 0:
                    return t("winter")
                case 90:
                    return t("spring/autumn")
                case 180:
                    return t("summer")
                default:
                    return `Day ${i}`
            }
        }

        const hourLabel = t("hour")
        const productionLabel = t("production")

        let productionGraphData = null;
        if (solarYieldProfile) {
            let datasets = [];
            solarYieldProfile.map((dailyProfile, i) => {
                // if (i % 90 === 0) {
                if ([0, 90, 180].includes(i)) {
                    let data = dailyProfile.map((value, hour) => { return { [hourLabel]: hour, [productionLabel]: value * 100 } })
                    datasets.push({
                        label: getLabel(i),
                        data: data,
                        borderColor: `rgb(${250 * (1 - Math.abs((i - 180) / 180))}, ${200}, ${200 * Math.abs((i - 180) / 180)})`,
                    })
                }
                return null;
            })
            productionGraphData = { "datasets": datasets }
        }

        let balanceGraphData = null;
        if (balanceProfile) {
            let datasets = [];
            balanceProfile.map((dailyProfile, i) => {
                if (i % 60 === 0) {
                    let data = dailyProfile.map((value, hour) => { return { "hour": hour, "balance": value } })
                    data.push({ "hour": 24, "balance": balanceProfile[0] })
                    datasets.push({
                        label: `Day ${i}`,
                        data: data,
                        borderColor: `rgb(${250 * (1 - Math.abs((i - 180) / 180))}, ${200}, ${200 * Math.abs((i - 180) / 180)})`,
                    })
                }
                return null;
            })
            balanceGraphData = { "datasets": datasets }
        }

        let consumptionGraphData = null;
        if (consumptionProfile) {
            let dataAsArray = [];
            consumptionProfile.map((value, hour) => {
                dataAsArray.push({ "hour": hour, "consumption": value })
                return null;
            });
            dataAsArray.push({ "hour": 24, "consumption": consumptionProfile[0] })

            consumptionGraphData = {
                datasets: [{
                    label: 'Consumption Profile (kW)',
                    data: dataAsArray,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                }]
            }
        }

        setGraphData({ "production": productionGraphData, "consumption": consumptionGraphData, "balance": balanceGraphData })

    }

    useEffect(() => {
        refreshGraphData();
    }, [solarYieldProfile, consumptionProfile, balanceProfile])

    // load consumption profile
    useEffect(() => {
        fetch('typical_load_profile.json')
            .then((response) => {
                // Check if the response status is OK (200) before attempting to parse the JSON.
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                // 'data' contains the parsed JSON data.
                let dataAsArray = [];
                Object.keys(data).forEach((x) => {
                    dataAsArray.push(data[x])
                });
                setConsumptionProfile(dataAsArray)
                verbose && console.log("[Calculator] loaded consumption profile: ", dataAsArray);
                setTotalConsumption(365 * dataAsArray.reduce((a, b) => a + b, 0))
            })
            .catch((error) => {
                console.error('Error loading JSON data:', error);
            });
    }, [])

    useEffect(() => {
        if (useLocalWeatherInCalculation) {
            setWeatherProfile(inputWeatherProfile)
        } else {
            setWeatherProfile(null)
        }
    }, [useLocalWeatherInCalculation, inputWeatherProfile])

    // this runs on component load:
    useEffect(() => {
        if (worker && consumptionProfile) {
            findBestSystem()
        }
    }, [worker, consumptionProfile])

    useEffect(() => {
        switch (calculatorStatus) {
            case "bestSystemFound":
                calculateYearlyProfile()
                break;
            case "yearlyPowerProfileCalculated":
                calculateBalance()
                break;
            case "balanceCalculated":
                refreshGraphData()
                break;
            default:
                break;
        }
    }, [calculatorStatus])


    // useEffect(() => {
    //     if (solarYieldProfile && consumptionProfile) {
    //         calculateBalance();
    //     }
    // }, [solarYieldProfile, consumptionProfile, wattage, orientation])

    // useEffect(() => {
    //     if (solarYieldProfile && consumptionProfile) {
    //         refreshGraphData();
    //     }
    // }, [bestSystem])

    useEffect(() => {
        if (solarYieldProfile && graphData.production) {
            setAppStatus("resultsReady")
        }
    }, [solarYieldProfile, graphData])


    return (
        <div className="calculator-container">

            <InputSection inputValues={inputValues} setInputValues={setInputValues} calculateYearlyProfile={calculateYearlyProfile} findBestSystem={findBestSystem} calculating={(calculatorStatus == "calculating")} />

            <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div style={{ flex: 1, marginRight: "10px" }}>
                    {yearlyBalanceValues && <ResultTable yearlyBalanceValues={yearlyBalanceValues} totalConsumption={totalConsumption} />}
                </div>
                <div style={{ flex: 1, marginLeft: "10px" }}>
                    {bestSystem && <SystemTable system={bestSystem} title="Best System" />}
                </div>
                <div>
                    <Accordion style={{ flex: 1, marginLeft: "10px" }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Show all Systems</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {bestSystem && systemsToCompare.map((system, index) => (
                                <SystemTable key={index} system={system} title={`System ${index}`} />
                            ))}
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>


            <Accordion style={{ width: "100%" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Show Graphs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* {graphData.consumption && <Graph data={graphData.consumption} options={{ scales: { x: { min: 0, max: 24 } } }} />}
                    {totalConsumption && <div>Total Consumption: {totalConsumption.toFixed(0)} kWh</div>} */}
                    {graphData.production && <Graph data={graphData.production} options={{ scales: { x: { min: 0, max: 24 } } }} />}
                    {graphData.balance && <Graph data={graphData.balance} options={{ scales: { x: { min: 0, max: 24 } } }} />}
                </AccordionDetails>
            </Accordion>



            {/* {solarYieldProfile && consumptionProfile && <button onClick={calculateBalance}>Calculate Balance</button>} */}

        </div >
    );
};

export default Calculator;