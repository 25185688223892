import { Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box, Card, CardContent, CardMedia, Typography, Button, Grid } from "@mui/material";
import { yellow } from "@mui/material/colors";
import { Divider } from "@mui/material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SavingsIcon from '@mui/icons-material/Savings';

import SavingsPerYearGrid from './SavingsPerYearGrid';
import SystemPresentationSection from './SystemPresentationSection';
import IconItemBox from "./IconItemBox";
import ResultScreenGraph from "./ResultScreenGraph";

import { useTranslation } from 'react-i18next';

import { logAppExit } from '../../utils/UsageTracking';

export default function SystemTile({ system, style = "compact", globalState }) {
    const { t } = useTranslation();
    const { brand, wattage, amortizationTime, imageSrc, savingsCO2, savingsEuro, price, orderLink } = system;
    const theme = useTheme();

    const returnRate = (100 / amortizationTime)

    const sectionSx = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingTop: 3,
        paddingBottom: 3
    }

    // console.log(system.graphData)



    const handleOrderButtonClick = (link) => {
        logAppExit(link, globalState)
        window.open(link, "_blank");
    }


    return (<div data-testid="system-tile">
        {(style === "extended") && (
            <Card sx={{
                // border: "none",
                // boxShadow: "none",
                border: `1px solid ${theme.palette.background.default}`,
                paddingLeft: 2,
                paddingRight: 2,
                flexShrink: 0
            }}>
                <CardContent>
                    <SystemPresentationSection system={system} style={style} />

                    <Divider />

                    <SavingsPerYearGrid savingsCO2={savingsCO2} savingsEuro={savingsEuro} amortizationTime={amortizationTime} returnRate={returnRate} />

                    <Divider />

                    <Grid item xs={10} sx={sectionSx}>
                        <Typography variant="h5">{price}€</Typography>
                        <Button variant="contained" color="primary" onClick={() => window.open(orderLink, "_blank")} sx={{ fontSize: "1em" }}>{t("Order Now")}</Button>
                    </Grid>

                    {system.graphData &&
                        <Accordion sx={{ boxShadow: 'none', border: 'none', borderTop: 'none' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{ justifyContent: 'center' }}
                            >
                                <Box flexGrow={1}>
                                    <Typography sx={{ paddingRight: 3, paddingLeft: 3, textAlign: 'center' }}>
                                        Details
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>

                                <ResultScreenGraph graphData={system.graphData} />
                            </AccordionDetails>
                        </Accordion>
                    }

                </CardContent>
            </Card>
        )}
        {(style === "compact") && (
            <Card sx={{
                // border: "none",
                // boxShadow: "none",
                bgcolor: theme.palette.secondary.verylight,
                margin: 2,
                flexShrink: 0,
            }} >
                <CardContent>
                    {/* <Typography variant="h6">{brand}</Typography> */}

                    <SystemPresentationSection system={system} style={style} />
                    <Grid item xs={10} sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}>
                        <IconItemBox
                            icon={<SavingsIcon sx={{ color: theme.palette.secondary.main, fontSize: 20 }} />}
                            value={savingsEuro.toFixed(0)}
                            unit="€"
                            tooltip={t("moneysavings tooltip")} />

                        <Typography variant="h6">{price}€</Typography>

                        <Button variant="contained" color="primary" onClick={() => handleOrderButtonClick(orderLink)} sx={{ fontSize: ".8em" }}>{t("visit site")}</Button>
                    </Grid>

                    <Accordion sx={{
                        boxShadow: 'none', border: 'none', borderTop: 'none', bgcolor: theme.palette.secondary.verylight ,
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ justifyContent: 'center' }}
                        >
                            <Box flexGrow={1}>
                                <Typography sx={{ paddingRight: 3, paddingLeft: 3, textAlign: 'center' }}>
                                    Details
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SavingsPerYearGrid savingsCO2={savingsCO2} savingsEuro={savingsEuro} amortizationTime={amortizationTime} returnRate={returnRate} />
                        </AccordionDetails>
                    </Accordion>

                </CardContent>
            </Card >
        )
        }
    </div>
    );
}
