import beta from "./beta.json";
import defaultConfig from "./default.json";
import zweihorn from "./zweihorn.json";
import dev from "./dev.json";
import sonnenrepublik from "./sonnenrepublik.json";

let configToUse = defaultConfig;

const env = process.env.REACT_APP_CONFIG_TO_USE
// const env = process?.env?.REACT_APP_CONFIG_TO_USE ? process.env.REACT_APP_CONFIG_TO_USE : "dev"

// switch (process.env.REACT_APP_CONFIG_TO_USE) {
switch (env) {
  case "beta":
    configToUse = {...configToUse, ...beta};
    break;
  case "zweihorn":
    configToUse = {...configToUse, ...zweihorn};
    break;
  case "sonnenrepublik":
    configToUse = {...configToUse, ...sonnenrepublik};
    break;
  case "dev":
    configToUse = {...configToUse, ...dev};
    break;
  }

export default configToUse;
